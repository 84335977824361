/* Global styles */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  overflow-x: hidden; 
}

.forgot-container {
  background-image: url('../Assets/Assets/login_Background.png');
  background-size: cover;
  background-position: bottom;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.forgot-form {
  padding: 30px;
  border-radius: 10px;
  width: 400px;
}

.forgot-form h2 {
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-group input:focus {
  outline: none;
  border-color: #00A3E0;
}

.forgot-btn {
  width: 40%;
  padding: 10px;
  background-color: #FF6600;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 auto;
}

.forgot-btn:hover {
  background-color: #E55A00;
}

.login-link {
  text-align: center;
  margin-top: 20px;
  color: #333;
}

.login-link a {
  color: #00A3E0;
  text-decoration: none;
  font-weight: bold;
}

.login-link a:hover {
  text-decoration: underline;
}

.error-message {
  font-size: 14px;
    font-weight: 700;
    color: #761b18;
    background-color: #f9d6d5;
    border-color: #f7c6c5;
    margin-top: 5px;
    border-radius: 4px;
    padding-left: 9px;
    text-align:start;
  }

/* Notification styles */
.notification-success {
  background-color: green !important;
  border-radius: 5px !important;
  color: white !important;
  padding: 17px !important;
  position: fixed;
  top: 20px;
  right: 20px;
  margin-left: 5px;
}

.notification-error {
  background-color: red !important;
  border-radius: 5px !important;
  color: white !important;
  padding: 17px !important;
  position: fixed;
  top: 20px;
  right: 20px;
  margin-left: 5px;
}

/* Media queries */
@media (max-width: 768px) {
  .forgot-form {
    width: 90%;
    padding: 20px;
  }

  .forgot-btn {
    width: 100%;
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .forgot-form {
    width: 95%;
    padding: 15px;
  }

  .forgot-form h2 {
    font-size: 1.5rem;
  }

  .forgot-btn {
    padding: 12px;
    font-size: 14px;
  }
}
