/* Global styles */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  overflow-x: hidden; 
}

/* Fixed header styling */
.header {
  background-color: #0A7AA3;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

/* Header content adjustment */
.header + .content {
  padding-top: 60px; 
}

/* Login page styling */
.login-container {
  background-image: url('../Assets/Assets/login_Background.png');
  background-size: cover;
  background-position: bottom;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.login-form {
  padding: 30px;
  border-radius: 10px;
  width: 400px;
}

.login-form h2 {
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-group input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

.forgot-password {
  text-align: right;
  margin-bottom: 15px;
}

.forgot-password a {
  color: #ccc;
  text-decoration: none;
  font-size: 14px;
}

.login-btn {
  width: 40%;
  padding: 10px;
  background-color: #FF6600;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 auto;
}

.register-link {
  text-align: center;
  margin-top: 20px;
  color: white;
}

.register-link a {
  color: #FF6600;
  text-decoration: none;
  font-weight: bold;
  text-decoration: underline;
}

.error-message {
  font-size: 14px;
  font-weight: 700;
  color: #761b18;
  background-color: #f9d6d5;
  margin-top: 5px;
  border-radius: 4px;
  padding-left: 9px;
  text-align:start;
}

/* Notification styles */
.notification-success {
  background-color: green !important;
  border-radius: 5px !important;
  color: white !important;
  padding: 17px !important;
  border-radius: 11px;
  position: fixed;
  top: 20px;
  right: 20px;
  margin-left: 5px;
}

.notification-error {
  background-color: red !important;
  border-radius: 5px !important;
  color: white !important;
  padding: 17px !important;
  border-radius: 11px;
  position: fixed;
  top: 20px;
  right: 20px;
  margin-left: 5px;
}

/* Password toggle button */
button.password-toggle-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
}
.password-input-container {
  position: relative;
  margin-bottom: 5px;
}
.password-input-container input {
  width: 100%;
  padding: 10px;
  padding-right: 40px;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
}
.password-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.password-input-wrapper input {
  width: 100%;
  padding: 10px;
  padding-right: 40px;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
}

/* Add this to your CSS file or a <style> tag */
.notification-custom {
  display: flex;
  align-items: center;
}

.notification-custom .notification-icon {
  margin-right: 10px; 
  color: #FFFFFF;
}

.notification-custom .notification-message {
  flex: 1;
}
