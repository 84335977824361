.splash-screen {
    background-image: url('../Assets/Assets/login_Background.png');
    background-size: cover;
    background-position: bottom;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.logo {
  width: 200px;
  height: 200px;
  background-image: url('../Assets/Assets/AK_society_logo.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}