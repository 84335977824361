/* Header.css */
.header {
  background-color: #0A7AA3;
  color: white;
  padding: 10px 20px; /* Reduced padding for better fit on smaller screens */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed; /* Fixes the header at the top */
  top: 0; /* Aligns the header to the top of the viewport */
  left: 0; /* Aligns the header to the left of the viewport */
  width: 100%; /* Ensures the header spans the full width of the viewport */
  z-index: 1000; /* Ensures the header stays on top of other content */
  box-sizing: border-box;
}

.mytitlle {
  font-size: 32px;
}

.header-icons {
  display: flex;
  gap: 23px; /* Adjust gap for better spacing on medium screens */
}

.icon-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.icon-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.icon-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}

/* Logo and Icon Sizing */
.logo-img {
  width: auto; /* Maintain aspect ratio */
  height: 50px; /* Default height for larger screens */
}

.icon-img {
  width: auto; /* Maintain aspect ratio */
  height: 40px; /* Default height for larger screens */
}

/* Add padding to the top of the main content to avoid overlap with the fixed header */
.poll-page {
  padding-top: 60px; /* Adjust based on the height of your header */
}

/* Media queries for responsiveness */

/* For tablets and small desktops */
@media (max-width: 1024px) {
  .logo-img {
    height: 75px; /* Larger than mobile but smaller than large screens */
  }

  .icon-img {
    height: 35px; /* Larger than mobile but smaller than large screens */
  }

  .header-icons {
    gap: 18px;
  }
}

/* For mobile devices */
@media (max-width: 768px) {
  .logo-img {
    height: 40px; /* Adjust height for better visibility on mobile */
  }

  .icon-img {
    height: 30px; /* Adjust height for better visibility on mobile */
  }

  .header-icons {
    gap: 16px;
  }

  .icon-button {
    padding: 4px;
  }
}

/* For very small devices (phones in portrait mode) */
@media (max-width: 480px) {
  .logo-img {
    height: 50px; /* Adjust height for very small screens */
  }

  .icon-img {
    height: 25px; /* Adjust height for very small screens */
  }

  .header-icons {
    gap: 13px;
  }

  .icon-button {
    padding: 3px;
  }
}
