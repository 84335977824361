html, body {
  overflow-y: hidden;
  height: 100%;
  margin: 0; 
}

.scrollable-content {
  height: 100vh; 
  overflow-y: scroll;
}

.poll-page {
  font-family: Arial, sans-serif;
}

.poll-list, .all-polls {
  margin-top: 20px;
}

.poll-item {
  margin-bottom: 10px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #ECECEC;
}

.poll-item .card-body {
  padding: 15px;
  box-shadow: 2px 3px 4px whitesmoke;
}

.options label {
  display: block;
  margin-bottom: 10px;
  color: #0A7AA3;
}

.radionames {
  color: #0A7AA3;
}

.buttons {
  margin-top: 20px;
}

.buttons button {
  margin-right: 10px;
}

.result-bar {
  height: 20px;
  background-color: #f0f0f0;
  margin-bottom: 10px;
  position: relative;
}

.bar {
  height: 100%;
  background-color: #4CAF50;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.notification-success {
  background-color: green !important;
  border-radius: 5px !important;
  color: white !important;
  padding: 17px !important;
  position: fixed;
  top: 20px;
  right: 20px;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 300px ;
}

.notification-error {
  background-color: red !important;
  border-radius: 5px !important;
  color: white !important;
  padding: 17px !important;
  position: fixed;
  top: 20px;
  right: 20px;
  margin-left: 5px;
}

.poll-container {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  background-color: #fff;
  overflow: hidden;
}

.success-message {
  background-color: #009688;
  color: white;
  padding: 10px;
  font-size: 14px;
}

.checkmark {
  margin-right: 5px;
}

.poll-question {
  padding: 15px;
  font-size: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.option-row {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 15px;
  position: relative;
  /* overflow: hidden; */
  justify-content: space-between;
  width: 95%;
}

.option-content {
  display: flex;
  align-items: center;
  position: absolute;
  left: 10px;
  z-index: 2;
}

.option-circle {
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 50%;
  margin-right: 10px;
}

.option-text {
  color: white;
  font-size: 14px;
}

.option-percentage {
  position: absolute;
  right: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  z-index: 1;
  border-radius: 57px 0 0 2px;
}

.percentage-text {
  color: white;
  font-size: 14px;
}

/* Media queries for responsiveness */

/* For tablets and small desktops */
@media (max-width: 1024px) {
  .poll-item {
    margin-bottom: 15px;
  }

  .poll-item .card-body {
    padding: 12px;
  }

  .poll-question {
    font-size: 14px;
  }

  .option-row {
    height: auto;
    flex-direction: row;
    margin-bottom: 20px;
    justify-content: space-between;
    height: 40px;
    padding: 8px;
  }

  .option-content {
    position: static;
    margin-bottom: 5px;
  }

  .option-circle {
    margin-right: 5px;
  }

  .option-percentage {
    position: absolute;
    right: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    z-index: 1;
    border-radius: 57px 0 0 2px;
  }
}

/* For mobile devices */
@media (max-width: 768px) {
  .poll-item {
    margin-bottom: 20px;
  }

  .poll-item .card-body {
    padding: 10px;
  }

  .poll-question {
    font-size: 12px;
  }

  .option-row {
    height: auto;
    flex-direction: row;
    margin-bottom: 20px;
    justify-content: space-between;
    height: 40px;
    padding: 8px;
  }

  .option-content {
    position: static;
    margin-bottom: 5px;
  }

  .option-circle {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }

  .option-text {
    font-size: 12px;
  }

  .option-percentage {
    position: absolute;
    right: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    z-index: 1;
    border-radius: 57px 0 0 2px;
  }

  .percentage-text {
    font-size: 12px;
  }

  .buttons button {
    margin-right: 5px;
    padding: 8px;
    font-size: 12px;
  }
}

/* For very small devices (phones in portrait mode) */
@media (max-width: 480px) {
  .poll-item {
    margin-bottom: 25px;
  }

  .poll-item .card-body {
    padding: 8px;
  }

  .poll-question {
    font-size: 10px;
  }

  .option-row {
    height: auto;
    flex-direction: row;
    margin-bottom: 20px;
    justify-content: space-between;
    height: 40px;
    padding: 8px;
  }

  .option-content {
    position: static;
    margin-bottom: 5px;
  }

  .option-circle {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }

  .option-text {
    font-size: 10px;
  }

  .option-percentage {
    position: absolute;
    right: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    z-index: 1;
    border-radius: 57px 0 0 2px;
  }

  .percentage-text {
    font-size: 10px;
  }

  .buttons button {
    margin-right: 5px;
    padding: 6px;
    font-size: 10px;
  }
}
