/* Global styles */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Registration page styling */
.register-container {
  background-image: url('../Assets/Assets/login_Background.png');
  background-size: cover;
  background-position: bottom;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.register-form {
  padding: 30px;
  border-radius: 10px;
  width: 400px;
}

.register-form h2 {
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-group input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

.create-btn {
  width: 40%;
  padding: 10px;
  background-color: #FF6600;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 auto;
}

.register-link {
  text-align: center;
  margin-top: 20px;
  color: white;
}

.register-link a {
  color: #FF6600;
  text-decoration: none;
  font-weight: bold;
  text-decoration: underline;
}
.form-group .password-input-container {
  margin-bottom: 50px;
}

.error-message {
    font-size: 14px;
    font-weight: 700;
    color: #761b18;
    background-color: #f9d6d5;
    margin-top: 5px;
    border-radius: 4px;
    padding-left: 9px;
    text-align:start;

}

/* Password toggle button */
button.password-toggle-btn-register {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}
.password-input-container {
  position: relative;
}
.password-input-wrapper {
  position: relative;
}
.password-input-wrapper input {
  width: 100%;
  padding-right: 40px; /* Make space for the icon */
}

/* Media queries */
@media (max-width: 768px) {
  .register-form {
    width: 90%;
    padding: 20px;
  }

  .create-btn {
    width: 40%;
  }

  .password-toggle-btn {
    right: 10px;
  }
}

@media (max-width: 480px) {
  .register-form {
    width: 95%;
    padding: 15px;
  }

  .register-form h2 {
    font-size: 1.5rem;
  }

  .create-btn {
    padding: 12px;
    font-size: 14px;
  }
}
 
  
  .notification-success {
    background-color: green !important;
    border-radius: 5px !important;
    color: white !important;
    padding: 17px !important;
    border-radius: 11px;
    position: fixed;
    top: 20px;
    right: 20px;
    margin-left: 5px;

  }
  
  .notification-error {
    background-color: red !important;
    border-radius: 5px !important;
    color: white !important;
    padding: 17px !important;
    border-radius: 11px;
    position: fixed;
    top: 20px;
    right: 20px;
    margin-left: 5px;
  }
  .notifymgs{
    background: #f9d6d5;
    padding: 0px;
    margin: 0px;
    border-radius: 10px;
  }
  
