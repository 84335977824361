html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}
/* .scrollable-content {
  height: 100vh; 
  overflow-y: scroll;
} */

.my-account {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
}

.account-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  max-width: 500px;
  width: 90%;
  margin: 20px auto;
  margin-top: 10% ;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profile-icon {
  text-align: center;
  margin-bottom: 20px;
}

.profile-icon img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.alert-message {
  width: 100%;
  margin-bottom: 20px;
}

form {
  width: 100%;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

/* input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
} */

input:focus {
  outline: none;
  border-color: #008080;
}

input:read-only {
  background-color: #f9f9f9;
}

.edit-button, .save-button {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #008080;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-button:hover, .save-button:hover {
  background-color: #006666;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: #008080;
}

@media (max-width: 768px) {
  .account-container {
    width: 95%;
    padding: 15px;
  }

  .profile-icon img {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 480px) {
  .account-container {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
  }

  .profile-icon img {
    width: 60px;
    height: 60px;
  }
}
.Note{
  color: red;
  font-weight: 600;
}
.btn-container{
  width: 100%;
  text-align: center;
}
.Back {
  background-color: #0A7AA3;
  border: none;
  color: #ffffff;
  width: 40%;
  border-radius: 4px;
}